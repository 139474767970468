import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { configureClient } from '@mediabank/client';
import { GlobalStyles } from '@mediabank/theme-v5';
import { NotificationsProvider, ToastProvider } from '@mediabank/uikit-v5';
import { CssBaseline, ThemeProvider } from '@mui/material';

import AuthWrapper from './AuthWrapper';
import ZenDesk from './components/Zendesk';
import useThemeSwitcher from './hooks/useThemeSwitcher';
import createMockServer from './mirage';
import Providers from './providers/index';
import LayoutProvider from './providers/LayoutProvider';
import AppRoutes from './routes/AppRoutes';
import store from './store';

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const App = () => {
    if (window.Cypress || window.location.hostname === 'localhost') {
        window.store = store;
    }

    createMockServer({ environment: process.env.NODE_ENV });

    // If you forget to configure it, it will takes the fallback values
    configureClient({
        DEPLOYMENT_ENV: process.env.REACT_APP_DEPLOYMENT_ENV,
        API_URL: process.env.REACT_APP_API_URL,
        API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT,
        JWT_URL: process.env.REACT_APP_JWT_URL,
        TAGS_API_URL: process.env.REACT_APP_TAGS_API_URL,
    });

    const [appTheme] = useThemeSwitcher();

    // This comment and the related PR has been created to trigger a dev build on master

    return (
        <Provider store={store}>
            <ZenDesk />
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={appTheme}>
                    <GlobalStyles />
                    <CssBaseline />
                    <BrowserRouter>
                        <NotificationsProvider>
                            <AuthWrapper>
                                <LayoutProvider>
                                    <ToastProvider>
                                        <Providers>
                                            <AppRoutes />
                                        </Providers>
                                    </ToastProvider>
                                </LayoutProvider>
                            </AuthWrapper>
                        </NotificationsProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </CacheProvider>
        </Provider>
    );
};

export default App;
