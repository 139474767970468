import { createServer } from 'miragejs';

import { mockPropertiesTabResponse } from './fixtures/propertiesTab';

// All paths defined here will be handled by mirage server
const mockEndpoints = [];

const createMockServer = ({ environment = 'development' }) => {
    createServer({
        environment,
        routes() {
            this.urlPrefix = process.env.REACT_APP_API_URL;
            this.logging = environment === 'development' ? true : false;

            this.get('/api/v1/assets/:id/properties', () => mockPropertiesTabResponse);

            this.passthrough(request => {
                const found = mockEndpoints?.filter(path => request.url.match(new RegExp(path)));

                return !found.length;
            });
        },
    });
};

export default createMockServer;
